<template>
  <div class="cart-detail-item">
    <table style="width:100%;">
      <tr v-for="(scope, key) in item.vip_info" :key="key" style="text-align:center;">
        <td style="width:15%;text-align:center;">
           <a-col :span=24>
              <img src="@/assets/images/web/music/vip_personal.svg"  v-if="scope.auth_type==2" alt="个人VIP"> 
              <img src="@/assets/images/web/music/vip_company.svg"  v-if="scope.auth_type==1" alt="企业VIP">
            </a-col>
        </td>
        <td style="width:15%;text-align:center;">
          <a-row>
          <a-col :span=24 style="text-align:center;">
              <span v-if="scope.vip_type==1">月度</span>
              <span v-if="scope.vip_type==2">季度</span>
              <span v-if="scope.vip_type==3">年度</span>
              <span v-if="scope.vip_type==4">定制</span>
            </a-col>
          </a-row>
        </td>
        <td style="width:40%;text-align:left;padding:12px;" >
            <a-row>
              <a-col :span="24">
                <p v-if="scope.vip_type==4">曲库类型：全部曲库</p>   
                <p v-if="scope.vip_type!=4">曲库类型：订阅曲库</p>   
              </a-col>
              <a-col :span="24">
                <p>下载限制：
                    <span v-if="scope.download_num != '-1' ">{{scope.download_num}}</span>
                    <span v-else>不限量/天</span>
                </p>   
              </a-col>
              <a-col :span="24">
                  <p v-if="scope.auth_type==1">商用说明：可企业商用</p>
                  <p v-if="scope.auth_type==2">商用说明：仅限个人使用，不可企业商用</p>
              </a-col>
           </a-row>
        </td>
        <td v-if="key == 0" :rowspan="item.vip_info.length" style="width:15%;border-left: 1px solid #E2E5E9;text-align:center;">
           <span>¥{{item.order_amount}}</span>
        </td>
        <td v-if="key == 0" :rowspan="item.vip_info.length" style="width:15%;border-left: 1px solid #E2E5E9;text-align:center;">
          <a-col :span="24">
            <span v-if="item.status==0">待支付</span>
            <span v-if="item.status==1">已取消</span>
            <span v-if="item.status==2">已取消</span>
            <span v-if="item.status==3">支付中</span>
            <span v-if="item.status==4">支付成功</span>
          </a-col>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },

    props: {
      item: Object,
    },
    methods: {

    },
  }
</script>

<style lang="scss" scoped>

</style>

